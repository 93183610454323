import { formatDateUI } from 'helpers/commonFunctions';
import React from 'react';
import { OWNERSHIP_STATUS } from '../../../constant';

export const OPTIONS_YES_NO = [
  { label: 'Có', value: true },
  { label: 'Không', value: false },
];

export const OPTIONS_YES_NO_STRING = [
  { label: 'Có', value: 'true' },
  { label: 'Không', value: 'false' },
];


// Trạng thái
export const STATUS_OPTIONS = [
  { value: 'SALE_RECEIVED', label: 'Đã tiếp nhận hồ sơ' },
  { value: 'WAIT_QTRR_AND_CPOS_APPROVE', label: 'Chờ QLRR/ VHPOS phê duyệt' },
  { value: 'WAIT_QTRR_APPROVE', label: 'Chờ QLRR phê duyệt' },
  { value: 'WAIT_CPOS_APPROVE', label: 'Chờ VHPOS duyệt MCC và giá vốn' },
  { value: 'WAIT_SALE_ADD_DOCUMENT', label: 'CBBH bổ sung mẫu biểu' },
  { value: 'WAIT_GDDV_APPROVE', label: 'Chờ GDDV duyệt' },
  { value: 'REJECT', label: 'Từ chối' },
  { value: 'REQUEST_ADDITIONAL', label: 'Yêu cầu bổ sung' },
  // { value: 'SIGNED', label: 'Đã kí hợp đồng' },
  { value: 'WAIT_VHPOS_APPROVE', label: 'Chờ Vận hành POS duyệt' },
  // { value: 'APPROVED', label: 'Đã duyệt' },
  { value: 'COMPLETE', label: 'Hoàn thành' },
];

export const RECEIPTION_STATUS_OPTIONS = [
  { value: 'INCOMPLETE', label: 'Chưa hoàn thiện' },
  { value: 'NEW', label: 'Hồ sơ mới' },
];

export const REGISTRY_SERVICE_OPTIONS = [
  { value: 'POS', label: 'Pos' },
  { value: 'ECOMPAY', label: 'Ecompay' },
];

export const COLUMNS_APPLICANT_LIST = [
  {
    Header: 'Mã POS/Ecompay',
    accessor: (row) => {
      return <><div className="d-flex justify-content-center"> <p style={{ width:'100%' }}>{row.cposId}</p></div></>;
    },
  },
  {
    Header: 'Tên doanh nghiệp',
    accessor: 'companyName',
    Cell: ({ row }) => {
      const name = row.original.info?.companyName ?? row.original.companyName ?? '';

      if (name.length > 45) {
        return (
          <span style={{ verticalAlign:'middle', fontWeight: 'bold', color: '#0688a7', textAlign: 'left' }} title={name}>
            {`${name.slice(0, 45)}...`}
          </span>
        );
      }

      return <span style={{ verticalAlign:'middle', fontWeight: 'bold', color: '#0688a7', textAlign: 'left' }}>{name}</span>;
    },
  },
  {
    Header: 'Mã ĐKKD',
    accessor: (row) => {
      return <span style={{ verticalAlign:'middle' }}>{row.businessNumber}</span>;
    },
  },
  {
    Header: 'Loại dịch vụ',
    accessor: (row) => {
      const result = REGISTRY_SERVICE_OPTIONS.find((item) => item.value === row.registryService);
      return <span style={{ verticalAlign:'middle' }}>{result !== undefined ? result.label : ''}</span>;
    },
  },
  {
    Header: 'Số điện thoại',
    accessor: (row) => {
      return <span style={{ verticalAlign:'middle' }}>{row.legalPhoneNumber}</span>;
    },
  },
  {
    Header: 'Số CMND/CCCD',
    accessor: (row) => {
      return <span style={{ verticalAlign:'middle' }}>{row.legalId}</span>;
    },
  },
  {
    Header: 'Thời gian đăng ký',
    accessor: (row) => {
      return <span style={{ verticalAlign:'middle' }}>{formatDateUI(row.createdDate)}</span>;
    },
  },
  {
    Header: 'Trạng thái',
    accessor: (row) => {
      const result = STATUS_OPTIONS.find((item) => item.value === row.status);
      return <span style={{ verticalAlign:'middle' }}>{result !== undefined ? result.label : ''}</span>;
    },
  },
];
export const COLUMNS_APPLICANT_ALERT_LIST = [
  {
    Header: 'Ngày Alert',
    accessor: (row) => {
      return <span style={{ verticalAlign:'middle' }}>{row.alertDate}</span>;
    },
  },
  {
    Header: 'Tên ĐVCNT của VPBANK',
    accessor: (row) => {
      return <span style={{ verticalAlign:'middle' }}>{row.dbAName}</span>;
    },
  },
  {
    Header: 'MST doanh nghiệp',
    accessor: (row) => {
      return <span style={{ verticalAlign:'middle' }}>{row.businessRegistrationNumber}</span>;
    },
  },
  {
    Header: 'Tên chủ DN',
    accessor: (row) => {
      return <span style={{ verticalAlign:'middle' }}>{row.principalName}</span>;
    },
  },
  {
    Header: 'Địa chỉ',
    accessor: (row) => {
      return <span style={{ verticalAlign:'middle' }}>{row.address}</span>;
    },
  },
  {
    Header: 'Lý do terminate từ ACQ',
    accessor: (row) => {
      return <span style={{ verticalAlign:'middle' }}>{row.terminationReason}</span>;
    },
  },
  {
    Header: 'BID listing',
    accessor: (row) => {
      return <span style={{ verticalAlign:'middle' }}>{row.listingCreated}</span>;
    },
  },
  {
    Header: 'Trạng thái',
    accessor: (row) => {
      return <span style={{ verticalAlign:'middle' }}>{row.status}</span>;
    },
  },
];

export const COLUMNS_RECEIPTION_APPLICANT_LIST = [
  {
    Header: 'Mã POS/Ecompay',
    accessor: 'cposId',
  },
  {
    Header: 'Tên doanh nghiệp',
    accessor: 'companyName',
    Cell: ({ row }) => {
      const name = row.original.info?.companyName ?? row.original.companyName ?? '';

      if (name.length > 45) {
        return (
          <span style={{ verticalAlign:'middle', fontWeight: 'bold', color: '#0688a7', textAlign: 'left' }} title={name}>
            {`${name.slice(0, 45)}...`}
          </span>
        );
      }

      return <span style={{ verticalAlign:'middle', fontWeight: 'bold', color: '#0688a7', textAlign: 'left' }}>{name}</span>;
    },
  },
  {
    Header: 'Mã ĐKKD',
    accessor: (row) => {
      return <span style={{ verticalAlign:'middle' }}>{row.businessNumber}</span>;
    },
  },
  {
    Header: 'Loại dịch vụ',
    accessor: (row) => {
      const result = REGISTRY_SERVICE_OPTIONS.find((item) => item.value === row.registryService);
      return <span style={{ verticalAlign:'middle' }}>{result !== undefined ? result.label : ''}</span>;
    },
  },
  {
    Header: 'Số điện thoại',
    accessor: (row) => {
      return <span style={{ verticalAlign:'middle' }}>{row.legalPhoneNumber}</span>;
    },
  },
  {
    Header: 'Số CMND/CCCD',
    accessor: (row) => {
      return <span style={{ verticalAlign:'middle' }}>{row.legalId}</span>;
    },
  },
  {
    Header: 'Thời gian đăng ký',
    accessor: (row) => {
      return <span style={{ verticalAlign:'middle' }}>{formatDateUI(row.createdDate)}</span>;
    },
  },
  {
    Header: 'Trạng thái',
    accessor: (row) => {
      const result = RECEIPTION_STATUS_OPTIONS.find((item) => item.value === row.status);
      return <span style={{ verticalAlign:'middle' }}>{result !== undefined ? result.label : ''}</span>;
    },
  },
];

export const APPLICANT_TABS = {
  CUSTOMER_INFO: 'CUSTOMER_INFO',
  REGISTRY_INFO: 'REGISTRY_INFO',
  DOCUMENT: 'DOCUMENT',
  VMSS: 'Kết quả ResultVMSS',
};

export const DOCTYPE_INIT = {
  GCN_DKKD: [],
  CMTND: [],
  HA_CH: [],
  SPOS_MB01: [],
  SPOS_MB02_POS: [],
  SPOS_MB02_ECOMPAY: [],
  SPOS_MB03: [],
  SPOS_MB04: [],
  SPOS_MB06: [],
  SPOS_MB07: [],
  SPOS_MB08: [],
  SPOS_REPORT_MAIL_POS: [],
  SPOS_REPORT_MAIL_ECOMPAY: [],
};

export const DOCUMENT_INIT = {
  docType: '',
  fileName: '',
  mediaUrl: '',
  documentId: '',
  docCheckNote: '',
  docCheckFailed: '',
  reference: null,
};

export const APPLICANT_PLAN_REVIEW = [
  {
    label:
      'Bằng kinh nghiệm và kỹ năng kiểm tra của mình, tôi/chúng tôi xác nhận nguồn thông tin mà Khách hàng cung cấp là phù hợp với thực tế và chịu hoàn toàn trách nhiệm về tính xác thực đối với những số liệu mà Khách hàng đã nêu trên. (Áp dụng đối với trường hợp Đơn vị kinh doanh đồng ý với bảng kê khai tình hình hoạt động kinh doanh của khách hàng tại Mẫu Phương án vay vốn)',
    value: 'CONFIRM',
  },
  {
    label:
      'Bằng kinh nghiệm và kỹ năng kiểm tra của mình, tôi/chúng tôi bổ sung/điều chỉnh một số thông tin mà Khách hàng cung cấp như sau và chịu hoàn toàn trách nhiệm về tính xác thực đối với những số liệu mà Khách hàng đã nêu và được bổ sung/điều chỉnh (Áp dụng đối với trường hợp Đơn vị kinh doanh chưa hoàn toàn đồng ý với bảng kê khai tình hình hoạt động kinh doanh của khách hàng tại Mẫu Phương án vay vốn)',
    value: 'ADJUST',
  },
];

export const LEGAL_POSITION_OPTIONS = [
  { value: 1, label: 'Chủ tịch HĐQT' },
  { value: 2, label: 'Tổng Giám đốc' },
  { value: 3, label: 'Giám đốc' },
  { value: 0, label: 'Khác' },
];

export const CARD_TYPE = [
  { value: 'Visa', label: 'Visa' },
  { value: 'Master', label: 'Master' },
  { value: 'Jcb', label: 'JCB' },
  { value: 'Napas', label: 'Napas' },
];
