const configApp = {
  API: `${process.env.REACT_APP_API_BASE_URL}/smeca-admin`,
  API_BIZ_LANDING_PAGE: process.env.REACT_APP_BIZ_LANDING_PAGE,
  API_CMB: process.env.REACT_APP_API_CMB_URL,
  API_CPOS: `${process.env.REACT_APP_API_BASE_URL}/biz-cpos/api`,
  API_KPI: `${process.env.REACT_APP_API_BASE_URL}/performance`,
  API_LDP: `${process.env.REACT_APP_API_BASE_URL}/bizmarketing`,
  API_LEAD: `${process.env.REACT_APP_API_BASE_URL}/sme-lead`,
  API_SME_PORTAL: `${process.env.REACT_APP_API_BASE_URL}/biz-portal`,
  API_URL_MODULE_SAVE_MONEY: process.env.REACT_APP_API_URL_MODULE_SAVE_MONEY,
  APP_DOMAIN: process.env.REACT_APP_DOMAIN,
  APP_FDI: `${process.env.REACT_APP_GATEWAY_BASE_URL}/corporate`,
  APP_SME_PORTAL: process.env.REACT_APP_SME_PORTAL,
  BIZ_FILE_API_URL: `${process.env.REACT_APP_API_BASE_URL}/biz-file-management/api`,
  BIZ_GATEWAY_MSA_API_URL: process.env.REACT_APP_GATEWAY_BASE_URL,
  BIZ_GATEWAY_URL: process.env.REACT_APP_GATEWAY_BASE_URL,
  CIB_API_URL: `${process.env.REACT_APP_API_BASE_URL}/biz-cib`,
  DIS_MEDIA_URI: process.env.REACT_APP_DIS_MEDIA_URL,
  G500_API_URL: `${process.env.REACT_APP_API_BASE_URL}/biz-grow500/v1.0`,
  G500_P1_API_URL: `${process.env.REACT_APP_API_BASE_URL}/bizgrow500/api/v1`,
  MEDIA_URI: process.env.REACT_APP_MEDIA_URI,
  OD_TERMINATION_API_URL: `${process.env.REACT_APP_API_BASE_URL}/overdrafts/api/admin`,
  QR_URL: process.env.REACT_APP_QR_URL,
  SLA_API_URL: `${process.env.REACT_APP_API_BASE_URL}/biz-sla`,
  SLP_CUS_DANGKY_URL: process.env.REACT_APP_SLP_DANGKY_URL,
};
export default configApp;
