import superagent from 'superagent';
import auth from './auth';
import CONFIG from '../config';
import message from './message';
import { saveAs } from 'file-saver';
import queryString from 'query-string';

const methods = ['get', 'post', 'put', 'patch', 'del'];

export const API_HOST = `${CONFIG.API}/api`;
export const API_LDP_HOST = `${CONFIG.API_LDP}/api`;
export const API_LEAD_HOST = `${CONFIG.API_LEAD}/api`;
export const API_KPI_HOST = `${CONFIG.API_KPI}/api`;
export const API_SME_PORTAL = `${CONFIG.API_SME_PORTAL}`;
export const API_BIZ_LANDING_PAGE = `${CONFIG.API_BIZ_LANDING_PAGE}`;
export const API_BIZ_GATEWAY_URL = `${CONFIG.BIZ_GATEWAY_URL}/corporate`;
let formatUrl = (host, path) => {
  return host + path;
};
export const login = (username, password) => {
  let request = superagent.post(`${API_HOST}/auth/login`);
  return request.send({
    username,
    password,
  });
};
export const qrLogin = (params) => {
  let request = superagent.post(`${API_HOST}/authen-qr/check-authen?uuid=${params}`);
  return request.query(params);
};
export const getUuid = (params) => {
  let request = superagent.post(`${API_HOST}/authen-qr/uuid`);
  return request.query(params);
};
export const mediaUrl = (url) => `${CONFIG.API}/media/${url}`;
export const download = (
  path,
  fileName,
  data,
  callback,
  method = 'GET',
  rawUrl = false,
  ignoreToken = false,
  headers = {},
  loadingIndicator = false,
) => {
  if (loadingIndicator) document.body.classList.add('loading-indicator');

  if (data && method === 'GET') {
    path = queryString.stringifyUrl({ url: rawUrl ? path : `${API_HOST}${path}`, query: data });
  } else {
    path = rawUrl ? path : `${API_HOST}${path}`;
  }

  return fetch(path, {
    method,
    headers: {
      ...(!ignoreToken && { Authorization: `Bearer ${auth.gettoken()}` }),
      ...headers,
    },
    ...(data && method === 'POST' && { body: JSON.stringify(data) }),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.blob();
      }
      throw response;
    })
    .then((myBlob) => {
      saveAs(myBlob, fileName);
    })
    .catch((err) => {
      try {
        err
          .json()
          .then((json) => message.error(json.message ? json.message : 'Lỗi không xác định'));
      } catch (e) {
        message.error('Lỗi không xác định');
      }
    })
    .finally(() => {
      if (loadingIndicator) document.body.classList.remove('loading-indicator');
      callback && callback();
    });
};
export const downloadMedia = (path, fileName) => {
  const request = superagent.get(path);
  request.set('Authorization', `Bearer ${auth.gettoken()}`);
  request.responseType('blob').then((data) => {
    var a = document.createElement('a');
    var url = window.URL.createObjectURL(data.body);
    a.href = url;
    a.download = fileName || 'export.xls';
    a.click();
    window.URL.revokeObjectURL(url);
  });
};

export default class ApiClient {
  constructor(host = API_HOST, customRequestDataInterceptor = null, customResponseBodyInterceptor = null) {
    methods.forEach((method) => {
      this[method] = (
        path,
        {
          params,
          data,
          silent,
          includeHeader,
          loadingIndicator = false,
          additionalHeader,
          timeout,
        } = {},
      ) => {
        return new Promise((resolve, reject) => {
          if (loadingIndicator) document.body.classList.add('loading-indicator');
          const request = superagent[method](formatUrl(host, path));
          if (timeout) {
            request.timeout(timeout);
          }
          if (auth.gettoken()) {
            request.set('Authorization', `Bearer ${auth.gettoken()}`);
          }

          if (additionalHeader) {
            request.set(Object.assign({}, additionalHeader));
          }

          if (params) {
            request.query(customRequestDataInterceptor ? customRequestDataInterceptor(method, params) : params);
          }
          if (data) {
            request.send(customRequestDataInterceptor ? customRequestDataInterceptor(method, data) : data);
          }

          request.end((error, { body, text, ...other } = {}) => {
            if (loadingIndicator) document.body.classList.remove('loading-indicator');
            if (error) {
              if (error.status === 401) {
                const iframeContainer = document.getElementById('iframeContainer');
                if (iframeContainer) iframeContainer.remove();
                const oldBot = document.getElementById('chatbot-script');
                if (oldBot) oldBot.remove();
                const oldFrame = document.getElementById('botplatform_iframe');
                if (oldFrame) oldFrame.parentNode.removeChild(oldFrame);
                auth.clear();
                window.location.href = 'digitalgate/login?url=' + window.location.pathname;
                return;
              }
              reject(body || error);
            } else if (
              (body && body.statusCode && body.statusCode !== '200' && body.statusCode !== 200 && body.statusCode !== 'OK') ||
              (customResponseBodyInterceptor && customResponseBodyInterceptor(body) === false)
            ) {
              reject(body);
            } else {
              includeHeader ? resolve({ body, ...other }) : resolve(body || text);
            }
          });
        });
      };
    });
  }

  empty() {}
}
