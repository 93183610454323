import * as moment from 'moment';
import config from "../config";


export const getLabelOptions = (value, options) => {
    if (options?.length) {
        return options.find((op) => op.value === value)?.label;
    }
    return null;
};
export const formatDateUI = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY');
    }
    return null;
};
export const formatDateTimeUI = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY HH:mm:ss');
    }
    return null;
};
const OBJECT_TYPE = {
    companyName: {type: 'text'},
    businessNumber: {type: 'text'},
    legalFullName: {type: 'text'},
    // legalPosition: { type: 'option', options: OPTIONS_POSITION },
    legalPositionDetail: {type: 'text'},
    legalEmail: {type: 'text'},
    legalInfoChange: {type: 'bool'},
    // legalChangeType: { type: 'option', options: OPTIONS_CHANGE_TYPE },
    // disbursementCurrency: { type: 'option', options: OPTIONS_MONEY },
    // intRepayMethod: { type: 'option', options: OPTIONS_REPAY_METHOD },
    // prinRepayMethod: { type: 'option', options: OPTIONS_REPAY_METHOD },
    legalChangeDetail: {type: 'text'},
    companyType: {type: 'bool'},
};
export const getYesNo = (value, defaultValue) => {
    if (value === true) {
        return '1';
    }
    if (value === false) {
        return '0';
    }
    return defaultValue;
};
export const getInitObject = (obj, data) => {
    const init = {...obj};
    if (data) {
        Object.keys(obj).forEach((key) => {
            if (data[key] !== null && data[key] !== undefined) {
                init[key] = data[key];
            }
        });
    }
    return init;
};
export const initObjectValue = (obj, data) => {
    const init = {...data};
    if (data) {
        Object.keys(obj).forEach((key) => {
            init[key] = data[key];
        });
    }
    return init;
};
export const convertDataSave = (values) => {
    const data = {};
    Object.keys(values).forEach((key) => {
        switch (OBJECT_TYPE[key].type) {
            case 'option':
                data[key] = values[key].value;
                break;
            default:
                data[key] = values[key];
        }
    });
    return data;
};
export const convertDataSave2 = (values, init) => {
    const data = {};
    Object.keys(init).forEach((key) => {
        data[key] = values[key];
    });
    return data;
};
export const removeEmptyFields = (obj) => {
    const data = {};
    Object.keys(obj).forEach((key) => {
        if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
            data[key] = obj[key];
        }
    });
    return data;
};

export const formatMoney = (value, unit) => {
    if (value || value === 0) {
        const money = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        if (unit) {
            return `${money} ${unit}`;
        } else {
            return money
        }
    }
    return '';
};
export const groupBy = (xs, key) => xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
}, {});
export const getLabelOther = (value, other, options) => {
    if (value === 0 || value === 'OTHER') {
        return other;
    }
    return getLabelOptions(value, options);
};

export function roundNumber(num, scale) {
    if (!num && num !== 0) {
        return null
    }
    if (!("" + num).includes("e")) {
        return +(Math.round(num + "e+" + scale) + "e-" + scale);
    } else {
        let arr = ("" + num).split("e");
        let sig = ""
        if (+arr[1] + scale > 0) {
            sig = "+";
        }
        return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    }
}

export function compareArray(a, b) {
    if (!a || !b)
        return false;

    if (a.length !== b.length)
        return false;

    for (let i = 0, l = a.length; i < l; i++) {
        if (b[i].indexOf(a[i]) < 0) {
            return false;
        }
    }
    return true;
}
export const getDocumentSignature =(obj)=>{
    if (obj && obj.documentSignatureList?.length) {
        for (let i = 0; i < obj.documentSignatureList.length; i++) {
            if (obj.documentSignatureList[i].signer === 'BOTH') {
                return obj.documentSignatureList[i];
            }
        }
        for (let i = 0; i < obj.documentSignatureList.length; i++) {
            if (obj.documentSignatureList[i].signer === 'CUSTOMER') {
                return obj.documentSignatureList[i];
            }
        }
        for (let i = 0; i < obj.documentSignatureList.length; i++) {
            if (obj.documentSignatureList[i].signer === 'VPBANK') {
                return obj.documentSignatureList[i];
            }
        }
    }
    return obj;
}

export const copyToClipboard = (textToCopy) => {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
    });
  }
}
